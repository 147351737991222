<template>
  <div>
    <div class="section-header">
      <div class="row" style="justify-content: right">
        <v-col cols="2" sm="2" md="2" class="pl-3" style="margin-right: 0">
          <v-select v-if="user &&(user.role_id == '612a40a51a1fcead8871e833')"
            v-model="appId"
            :items="items2"
            item-value="id"
            item-text="name"
            label="App Name #  "
            class="app-name"
            style="min-width: 100%;"
            @input="selectAppName(appId)"
            outlined
            >
          </v-select>
        </v-col>
        <div
          class="col-md-2 col-sm-6"
          :class="statusTime ? 'text-right custom-menu' : 'text-right'"
        >
          <v-menu
            v-model="v_startDate"
            :close-on-content-click="false"
            :nudge-right="100"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="pl-5"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                :label="$t('header.start')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker v-model="startDate" @input="v_startDate = false">
            </v-date-picker>
          </v-menu>
        </div>
        <div
          class="col-md-2 col-sm-6"
          :class="statusTime ? 'text-right custom-menu' : 'text-right'"
        >
          <v-menu
            v-model="v_endDate"
            :close-on-content-click="false"
            :nudge-right="100"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                :label="$t('header.finish')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="v_endDate = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div
          class="col-md-2 col-sm-12"
          :class="statusTime ? 'text-right custom-menu' : 'text-right'"
        >
          <v-text-field
            v-model="v_search"
            append-icon="mdi-magnify"
            :label="$t('header.search')"
            single-line
            hide-details
            clearable
            class="pl-5"
          />
        </div>

        <div class="col-md-1 col-sm-12" style="width: 100%; text-align: center">
          <v-btn
            style="display: inline-block; margin-top: 13px"
            class="white--text btn-btn-sucess"
            color="primary"
            depressed
            @click="getData()"
          >
            {{ $t("header.search") }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
export default {
  props: [
    "darkmode",
    "status",
    "params",
    "dataDelete",
    "userPermission",
    "user",
    "time",
    "startTime",
    "endTime",
  ],
  data() {
    return {
      data: {
        descendingFormat: [true],
        sortByFormat: ["amount_win"],
        descending: true,
        type: "",
        sortBy: "amount_win",
        page: 1,
        rowsPerPage: 10,
        fields: [
          "_id.user_name",
          "_id.balance",
          "amount",
          "amount_win",
          "_id.app_id",
        ],
        search: "",
        startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
        endDate: moment().format("YYYY-MM-DD") + "T23:59:59",
      },
      appId : 0,
      items: [
        { name: "All", id: 0 },
        { name: "aa2288", id: 1 },
        { name: "wa855", id: 2 },
        { name: "2bwinner", id: 3 },
        { name: "cf9", id: 4 },
        { name: "cflive", id: 5 },
        { name: "aa18", id: 6 },
      ],
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      v_startDate: null,
      v_endDate: null,
      loader1: false,
      v_startTime: "00:00",
      v_endTime: "23:59",
      statusTime: false,
      v_search: "",
    };
  },
  watch: {
    time: function (newVal) {
      // Emit this information to the parents component
      this.startDate = newVal.startDate;
      this.endDate = newVal.endDate;
      this.v_startTime = newVal.startTime;
      this.v_endTime = newVal.endTime;
      this.statusTime = newVal.statusTimeOrigin;
    },
  },
  mounted() {
    if (this.$cookies.get("channel-type")) {
      this.channelType = {
        name: this.$cookies.get("channel-type"),
        id: parseInt(this.$cookies.get("channel-type")),
      };
    }
    if (this.$cookies.get("app_id")) {
      this.appId = parseInt(this.$cookies.get("app_id"));
    }
  },
  computed: {
    titleInfo: function () {
      if (this.user) {
        if (
          this.user.role_name == "DEVELOPER" ||
          this.user.role_name == "MANAGER" ||
          this.user.role_name == "SPECIAL-MANAGER"
        )
          return " Company Statement";
        else if (
          this.user.role_name == "COMPANY" ||
          this.user.role_name == "SUB-COMPANY"
        )
          return " Senior Statement";
        else if (
          this.user.role_name == "SENIOR-D" ||
          this.user.role_name == "SUB-SENIOR-D"
        )
          return " Master Statement";
        else if (
          this.user.role_name == "MASTER-D" ||
          this.user.role_name == "SUB-MANAGER-D"
        )
          return " Agent Statement";
        else if (
          this.user.role_name == "AGENT-D" ||
          this.user.role_name == "SUB-AGENT-D"
        )
          return " Player Statement";
        else return "Loading...";
      } else return "Loading...";
    },
    reportUrl: function () {
      if (this.user) {
        if (
          this.user.role_name == "DEVELOPER" ||
          this.user.role_name == "MANAGER" ||
          this.user.role_name == "SPECIAL-MANAGER"
        )
          return "company";
        else if (
          this.user.role_name == "COMPANY" ||
          this.user.role_name == "SUB-COMPANY"
        )
          return "company";
        else if (
          this.user.role_name == "SENIOR-D" ||
          this.user.role_name == "SUB-SENIOR-D"
        )
          return "company";
        else if (
          this.user.role_name == "MASTER-D" ||
          this.user.role_name == "SUB-MASTER-D"
        )
          return "company";
        else if (
          this.user.role_name == "AGENT-D" ||
          this.user.role_name == "SUB-AGENT-D"
        )
          return "company";
        else return null;
      } else return null;
    },
  },
  methods: {
    selectAppName(appId) {
      this.$cookies.set("app_id", appId);
      this.appId = parseInt(this.$cookies.get("app_id"));
      if (
        parseInt(this.$cookies.get("app_id")) == 0 ||
        parseInt(this.$cookies.get("app_id")) == 1 ||
        parseInt(this.$cookies.get("app_id")) == 2 ||
        parseInt(this.$cookies.get("app_id")) == 3 ||
        parseInt(this.$cookies.get("app_id")) == 4 ||
        parseInt(this.$cookies.get("app_id")) == 5 ||
        parseInt(this.$cookies.get("app_id")) == 6 
      )
        this.$connectSocket(
          this.$cookies.get("token"),
          this.$cookies.get("app_id")
        );
      //this.$router.go();
    },
    selectChannelType(channelType) {
      this.$cookies.set("channel-type", channelType);
      this.channelType = {
        name: this.$cookies.get("channel-type"),
        id: parseInt(this.$cookies.get("channel-type")),
      };
      if (
        parseInt(this.$cookies.get("channel-type")) == 2 ||
        parseInt(this.$cookies.get("channel-type")) == 3 ||
        parseInt(this.$cookies.get("channel-type")) == 4
      )
        this.$connectSocket(
          this.$cookies.get("token"),
          this.$cookies.get("channel-type")
        );
      this.$router.go();
    },
    closeDelete() {
      this.dialogDelete = !this.dialogDelete;
    },
    async getData() {
      this.loader1 = true;
      this.startTime = this.v_startTime;
      this.endTime = this.v_endTime;
      let formatStartDate = "";
      let formatEndDate = "";
      // if(this.statusTime){
      //   formatStartDate = this.startDate + "T" + this.startTime + ":00";
      //   formatEndDate = this.endDate + "T" + this.endTime + ":59";
      // }else{
      //   formatStartDate = this.startDate;
      //   formatEndDate = this.endDate;
      // }
      formatStartDate = this.startDate + "T" + this.startTime + ":00";
      formatEndDate = this.endDate + "T" + this.endTime + ":59";
      this.data.startDate = formatStartDate;
      this.data.endDate = formatEndDate;
      this.data.statusTime = this.statusTime ? true : false;
      this.data.search = this.v_search;
      this.data.fields = this.calFields();
      let preData = {
        appId:this.appId,
        data: this.data,
        user_id: this.user._id,
        reportUrl: this.reportUrl,
      };
      this.fetchListStatement(preData).then(() => {
        let date = {
          appId:this.appId,
          startDate: formatStartDate,
          endDate: formatEndDate,
          statusTime: this.statusTime,
        };
        this.loader1 = false;
        this.$emit("child-checkbox", date);
      });
    },
    calFields() {
      if (
        this.user.role_name == "DEVELOPER" ||
        this.user.role_name == "MANAGER" ||
        this.user.role_name == "SPECIAL-MANAGER"
      ) {
        return ["_id.com", "_id.app_id", "amount", "amount_win", "amount_lose"];
      } else if (
        this.user.role_name == "COMPANY" ||
        this.user.role_name == "SUB-COMPANY"
      ) {
        return ["_id.com", "_id.app_id", "amount", "amount_win", "amount_lose"];
      } else if (
        this.user.role_name == "SENIOR-D" ||
        this.user.role_name == "SUB-SENIOR-D"
      )
        return ["_id.com", "_id.app_id", "amount", "amount_win", "amount_lose"];
      else if (
        this.user.role_name == "MASTER-D" ||
        this.user.role_name == "SUB-MASTER-D"
      )
        return ["_id.com", "_id.app_id", "amount", "amount_win", "amount_lose"];
      else if (
        this.user.role_name == "AGENT-D" ||
        this.user.role_name == "SUB-AGENT-D"
      )
        return ["_id.com", "_id.app_id", "amount", "amount_win", "amount_lose"];
      else return this.user.role_name;
    },
    ...mapActions("$_oCStatementSummary", ["fetchListStatement"]),
  },
};
</script>